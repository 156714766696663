<template>
  <div>
    <v-app>
      <CookiePopup />
      <Header />
      <div class="vv-app">
        <v-main style="max-width: 1400px">
          <slot></slot>
        </v-main>
      </div>
      <Footer class="mt-14" />
    </v-app>
  </div>
</template>

<style scoped lang="scss">
.vv-app {
  width: 100%;
  max-width: 1400px;
  align-self: center;
  /* border: 1px solid rgba(128, 128, 128, 0.117); */
  position: relative;
}
</style>
