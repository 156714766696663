<template>
  <v-footer style="height: auto" class="pa-10 bg-grey-darken-2 pb-8 text-white">
    <v-row align="start">
      <v-col class="d-flex flex-column ml-16">
        <v-img max-height="120" max-width="180" src="/von-rundstedt-logo.png" contain />
        <p class="text-caption pa-0 ma-0">von Rundstedt & Partner Schweiz AG</p>
        <p class="text-caption">+41 58 332 25 00</p>
        <v-spacer />
        <p class="text-caption">Copyright &copy; {{ new Date().getFullYear() }}</p>
      </v-col>
      <v-col>
        <v-card-title class="mb-0 pa-0">{{ homeTitel[locale] }}</v-card-title>
        <p v-for="(link, i) in homeLinks" :key="i" class="mt-0 mb-0 white--text">
          <v-btn
            variant="text"
            exact
            style="text-decoration: none"
            class="mt-0 mb-0 white--text"
            :to="{ path: `${link.link}`, hash: `${link.hash}` }"
          >
            {{ link.name[locale] }}
          </v-btn>
        </p>
      </v-col>
      <v-col>
        <v-card-title class="mb-0 pa-0">{{ programmTitel[locale] }}</v-card-title>
        <p v-for="(link, i) in programmLinks" :key="i" class="mt-0 mb-0 white--text">
          <v-btn
            variant="text"
            exact
            style="text-decoration: none"
            class="mt-0 mb-0 white--text"
            :to="{ path: `${link.link}`, hash: `${link.hash}` }"
          >
            {{ link.name[locale] }}
          </v-btn>
        </p>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script setup>
const { locale } = useI18n()
const homeTitel = {
  de: 'Über E4F',
  en: 'ABOUT E4F',
  it: 'A PROPOSITO DI E4F',
  fr: 'ABOUT E4F'
}
const homeLinks = [
  {
    name: {
      de: 'Why E4F',
      en: 'Why E4F',
      it: 'Perchè E4F',
      fr: 'Why E4F'
    },
    link: '/',
    hash: '#WHY'
  },
  {
    name: {
      de: 'E4F Kompetenzmodell',
      en: 'E4F Competency model',
      it: 'Modello di competenze E4F',
      fr: 'E4F Competency model'
    },
    link: '/',
    hash: '#KOMPETENZ'
  },
  {
    name: {
      de: 'Impressum',
      en: 'Imprint',
      it: 'Imprint',
      fr: 'Imprint'
    },
    link: '/impressum',
    hash: ''
  },
  {
    name: {
      de: 'Datenschutz',
      en: 'privacy',
      it: 'privacy',
      fr: 'privacy'
    },
    link: '/datenschutz',
    hash: ''
  }
]
const programmTitel = {
  de: 'Programm',
  en: 'Programme',
  it: 'PROGRAMMA',
  fr: 'Programme'
}

const programmLinks = [
  {
    name: {
      de: 'Überblick',
      en: 'Overview',
      it: 'Panoramica',
      fr: 'Overview'
    },
    link: '/programm',
    hash: ''
  },
  {
    name: {
      de: '360° Online Assessment',
      en: '360° Online Assessment',
      it: '360° Online Assessment',
      fr: '360° Online Assessment'
    },
    link: '/programm/E4F360Assessment',
    hash: ''
  },
  {
    name: {
      de: 'Coaching',
      en: 'Coaching',
      it: 'Coaching',
      fr: 'Coaching'
    },
    link: '/programm/coaching',
    hash: ''
  },
  {
    name: {
      de: 'Ihr Learning Journey',
      en: 'Your Learning Journey',
      it: 'Il percorso di apprendimento',
      fr: 'Your Learning Journey'
    },
    link: '/programm/Journey',
    hash: ''
  },
  {
    name: {
      de: 'Starten Sie Ihr Programm',
      en: 'Start your individual programme',
      it: 'Inizia il programma individuale',
      fr: 'Start your individual programme'
    },
    link: '/programm',
    hash: ''
  }
]
</script>
