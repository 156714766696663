<script setup>
import { storeToRefs } from 'pinia'
import { API } from 'aws-amplify'
import * as mutations from '@@/src/graphql/mutations'
const { locale, locales, setLocale } = useI18n()
const { setUser } = useAuthStore()
const { user } = storeToRefs(useAuthStore())
const emits = defineEmits(['languageSwitched'])

const languageMap = {
  en: 'English',
  de: 'Deutsch',
  it: 'Italiano',
  fr: 'Français'
}

const availableLocales = computed(() => {
  return locales.value.filter(index => index.code !== locale.value)
})

async function switchLanguage(code) {
  setLocale(code)
  const { id, _version } = user.value

  const updatedUserData = {
    id,
    sprache: languageMap[code],
    _version
  }

  const { data } = await API.graphql({
    query: mutations.updateUser,
    variables: {
      input: updatedUserData
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })

  const updatedUser = data.updateUser
  setUser(updatedUser)
  emits('languageSwitched')
}
</script>

<template>
  <div>
    <v-btn id="menu-activator" variant="text">
      <!-- Die speichern alles intern als i im design ist es aber als IT gewünscht -->
      {{ locale }}
    </v-btn>
    <v-menu top activator="#menu-activator">
      <v-list>
        <v-list-item v-for="lang in availableLocales" :key="lang.code" :value="lang.code">
          <v-list-item-title variant="text" @click="switchLanguage(lang.code)">{{
            lang.name
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
