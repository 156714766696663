<script setup>
const dialog = useState('showCookieModal')
const { locale } = useI18n()

const acceptGDPR = () => {
  dialog.value = false
  const cookie = useCookie('gdprAccepted')
  cookie.value = true
}

const accept_all = {
  de: 'Alle Akzeptieren',
  en: 'Accept All',
  it: 'Accetta tutto',
  fr: 'Accepter tout'
}

const accept_selected = {
  de: 'Auswahl bestätigen',
  en: 'Confirm selection',
  it: 'Conferma selezione',
  fr: 'Confirmer la sélection'
}

const essential_cookies = {
  de: 'Notwendige Cookies',
  en: 'Essential cookies',
  it: 'Cookie essenziali',
  fr: 'Cookies essentiels'
}

const privacy_settings = {
  de: 'Datenschutzeinstellungen',
  en: 'Privacy settings',
  it: 'Impostazioni sulla privacy',
  fr: 'Paramètres de confidentialité'
}

const cookie_text = {
  de: 'Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern.',
  en: 'We use cookies on our website. Some of them are essential, while others help us improve this website and your experience.',
  it: 'Utilizziamo i cookie sul nostro sito web. Alcuni di essi sono essenziali, mentre altri ci aiutano a migliorare questo sito web e la tua esperienza.',
  // eslint-disable-next-line quotes
  fr: "Nous utilisons des cookies sur notre site web. Certains d'entre eux sont essentiels, tandis que d'autres nous aident à améliorer ce site web et votre expérience."
}
</script>

<!-- cookie popup vuetify  -->
<template>
  <v-dialog
    v-model="dialog"
    class="cookie"
    style="max-width: 1300px"
    height="100%"
    location="bottom"
    transition="dialog-bottom-transition"
    persistent
    close-delay="2000"
  >
    <v-card
      style="
        position: absolute;
        bottom: -15px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
      "
      class="pa-2 px-md-6 pt-md-6"
    >
      <v-row>
        <v-col cols="12" md="7">
          <v-card-title>
            <h3>{{ privacy_settings[locale] }}</h3>
          </v-card-title>
          <v-card-text>
            {{ cookie_text[locale] }}
            <p></p>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="2" class="pl-5 pt-0 d-flex flex-column justify-center">
          <!-- vuetify checkbox -->
          <v-checkbox density="compact" hide-details :model-value="true" disabled label="Checkbox">
            <template #label>
              <p style="font-size: 0.6em">{{ essential_cookies[locale] }}</p>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" md="3" class="pa-5 d-flex flex-column">
          <v-btn
            block
            aria-label="Akzeptiere Alle Cookies"
            color="primary"
            flat
            @click="acceptGDPR"
          >
            {{ accept_all[locale] }}
          </v-btn>
          <v-btn
            block
            class="mt-4"
            aria-label="Akzeptiere Alle Cookies"
            color="primary"
            variant="outlined"
            @click="acceptGDPR"
          >
            {{ accept_selected[locale] }}
          </v-btn>
        </v-col>
      </v-row>
      <hr class="mt-5" style="width: 98%; margin: auto" />
      <!-- button for impressum and Datenschutz -->
      <div color="black" class="d-flex justify-space-between justify-md-end">
        <v-btn aria-label="Impressum" variant="text" to="/impressum">
          {{ $t('imprint') }}
        </v-btn>
        <v-btn aria-label="Datenschutz" variant="text" to="/datenschutz">
          {{ $t('privacy') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
