<script setup>
import { storeToRefs } from 'pinia'
import { useDisplay } from 'vuetify'
const { locale } = useI18n()
const menuDialog = ref(false)
const authStore = useAuthStore()
const { logout } = authStore
const { user } = storeToRefs(authStore)

const { mobile } = useDisplay()

const logoutUser = async () => {
  await logout()
  navigateTo('/')
}

const menuItems = [
  {
    name: {
      de: 'Über E4F',
      en: 'About E4F',
      it: 'A PROPOSITO DI E4F',
      fr: 'About E4F'
    },
    route: '/'
  },
  {
    name: {
      de: 'Programm',
      en: 'Programme',
      it: 'Programma',
      fr: 'Programme'
    },
    route: '/programm'
  }
]

const reportItem = {
  name: {
    de: 'Zum Assessment',
    en: 'Go to Assessment',
    it: 'Sulla Valutazione',
    // eslint-disable-next-line quotes
    fr: `Aller à l'évaluation`
  },
  route: '/report'
}

const dashboardItem = {
  name: {
    de: 'Dashboard',
    en: 'Dashboard',
    it: 'Dashboard',
    fr: 'Dashboard'
  },
  route: '/admin/dashboard'
}

const indicateStakeholder = {
  name: {
    de: 'Stakeholder einladen',
    en: 'Indicate Stakeholder',
    it: 'Invitare Stakeholder',
    fr: 'Indiquer les parties prenantes'
  },
  route: '/report/indicateStakeholder'
}

const reportGroups = new Set(['Client', 'Stakeholder'])
const dashboardGroups = new Set(['Admin', 'Partner'])

const filteredMenuItems = computed(() => {
  let items = [...menuItems]

  if (!user.value) {
    return items
  }
  if (reportGroups.has(user.value.group)) {
    items = [...items, reportItem]
    if (user.value.Klient?.id) {
      items = [...items, indicateStakeholder]
    }
    return items
  }
  if (dashboardGroups.has(user.value.group)) {
    return [...items, dashboardItem]
  }
  return items
})
</script>
<template>
  <div style="position: relative">
    <v-navigation-drawer
      v-if="mobile"
      v-model="menuDialog"
      style="height: 50vh !important"
      z-index="100"
      width="500"
      location="right"
      temporary
    >
      <v-card style="position: relative; height: 80%" class="rounded-0" elevation="0">
        <v-btn
          style="position: absolute; right: 10px; top: 10px"
          variant="text"
          @click="menuDialog = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
        <LanguageSwitcher @language-switched="menuDialog = false" />
        <v-row class="ma-0" style="height: 20vh" justify="center" align="center">
          <div>
            <v-list>
              <v-list-item
                v-for="(item, idx) in filteredMenuItems"
                :key="idx"
                style="font-weight: 300; font-size: 1.2em"
                @click="navigateTo(item.route)"
              >
                <template #prepend>
                  <v-icon icon="mdi-menu-right" />
                </template>
                {{ item.name[locale] }}
              </v-list-item>
            </v-list>
          </div>
        </v-row>
        <v-row class="mt-15" justify="center">
          <div style="width: 50%">
            <v-btn v-if="!user" to="/login" variant="outlined" color="green" block>Login</v-btn>
            <div v-else>
              <v-btn variant="outlined" block color="red" @click="logoutUser">Logout</v-btn>
            </div>
          </div>
        </v-row>
      </v-card>
    </v-navigation-drawer>
    <div v-if="!mobile" app flat class="d-flex" style="justify-content: center; height: 65px">
      <v-row style="max-width: 1400px" align="center" justify="center">
        <!-- on click go to / -->
        <v-col cols="2" style="cursor: pointer" @click="navigateTo('/')">
          <img style="height: 200px; position: absolute; top: 0; z-index: 10" src="/e4flogo.png" />
        </v-col>
        <v-col cols="8">
          <v-btn
            v-for="(item, idx) in filteredMenuItems"
            :key="idx"
            variant="text"
            size="large"
            style="font-weight: 500"
            @click="navigateTo(item.route)"
          >
            {{ item.name[locale] }}
          </v-btn>
        </v-col>
        <LanguageSwitcher />
        <v-btn v-if="!user" to="/login" variant="outlined" color="success">LOGIN</v-btn>
        <div v-else>
          <v-btn variant="outlined" color="error" @click="logoutUser">LOGOUT</v-btn>
        </div>
      </v-row>
    </div>
    <v-img
      :class="mobile ? '' : 'mt-3'"
      src="/header_vr_schweiz.jpg"
      style="height: 70vh; position: relative"
      cover
    >
      <img
        v-if="mobile"
        src="/e4flogo.png"
        style="width: 200px; height: 200px; position: absolute; top: 0px; left: 80px"
      />
      <div v-if="mobile" style="position: absolute; right: 60px; top: 14px">
        <v-btn class="ml-5" color="grey" icon="mdi-menu" size="large" @click="menuDialog = true" />
      </div>
    </v-img>
  </div>
</template>

<style scoped lang="scss">
.v-toolbar__content {
  justify-content: center;
}
</style>
